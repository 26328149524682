// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  siteUrl: 'http://localhost:4200/',
  apiUrl: 'https://api.rssaddles.co.uk/',
  enableDebug: false,
  HASH_IDS_SALT: '67sfgdja98ekd8ckls9ekewchcuds9edlskf',
  CUSTOMER_APPOINTMENT_TOKEN_COOKIE_KEY: 'rs_customer_appt_response',
  stripePublishableKey: 'pk_test_POofrQN9y4lIqLWIuYuwVA0W',
  firebase : {
    apiKey: "AIzaSyBOBLFVnmqWKuGKW2cp3QdE00QVDwhDzSs",
    authDomain: "rs-saddles.firebaseapp.com",
    projectId: "rs-saddles",
    storageBucket: "rs-saddles.appspot.com",
    messagingSenderId: "151481545411",
    appId: "1:151481545411:web:458bff9298924c8b2e2876",
    measurementId: "G-ZQMQE2QFLJ"
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
